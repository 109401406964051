:root {
  --Primary-color: #0f2d6a;
  --Secondary-color: #00aeef;
  --White: #ffffff;
  --Grey-1: #f2f2f2;
  --Grey-2: #f2f5f8;
  --Grey-3: #edf0f5;
  --Grey-4: #e4e5e7;
  --Grey-5: #6a6c6f;

  --text-primary: #0f172a;

  --surface-50: #f1f5f9;
  --surface-100: #e2e8f0;
  --surface-200: #cbd5e1;
  --surface-300: #94a3b8;
  --surface-500: #475569;
  --surface-600: #334155;

  --teal-50: #e7f9f9;
  --teal-100: #cff3f3;
  --teal-200: #a0e7e7;
  --teal-300: #71dada;
  --teal-400: #13c2c2;
  --teal-500: #08979c;
  --teal-600: #0b7474;
  --teal-700: #084e4e;
  --teal-800: #052e2e;

  --green-50: #effce8;
  --green-400: #52c41a;
  --green-600: #398712;
  --green-700: #265a0c;

  --lime-300: #e5fb6a;

  --navy-400: #1d5bbf;

  --blue-50: #e5f3ff;
  --blue--400: #1890ff;
  --blue-500: #004f99;
  --blue-600: #004f99;

  --yellow-50: #fefbe6;
  --yellow-400: #fadb14;
  --yellow-600: #a47d06;
  --yellow-700: #816204;

  --orange-400: #fa8c16;

  --purple-400: #722ed1;

  --pink-400: #eb2f96;

  --red-50: #fee7e8;
  --red-400: #e31636;
  --red-200: #fb9da2;
  --red-600: #8c221c;
  --red-700: #620409;
  --red-800: #310205;
}

.MuiLinearProgress-root {
  background-color: var(--White) !important;
}

.MuiLinearProgress-bar {
  background-color: var(--surface-300) !important;
}
